button {
    cursor: pointer;
}

.modal-title {
    display: inline-block;
}

.modal-top-bar > button {
    position: fixed;
    top: 0;
    right: 0;
    background: none;
    border: none;
}


/*  */


.modal-body > form > textarea {
    display: block;
    min-width: 300px;
    min-height: 100px;
}

.modal-body > form > button {
    display: block;
    margin: 20px 0;
}


/*  */



/* .app-container {
    height: 100vh;
}

.body-container {
    height: 90%;
    overflow-y: scroll;
    display: inline-block;
} */
