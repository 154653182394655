.messageItem {
    /* max-width: 350px; */
    background-color: #ebebeb;
    margin: 20px 0;
}

/*  */

.message-top-bar {
    padding: 8px 5px;
    background-color: #d6d6d6;
}

.message-top-bar > div {
    display: inline-block
}

.message-phone-number {
    width: 30%;
    text-align: left;
}

.message-date-time {
    width: 50%;
    text-align: center;
}

.message-flag-report {
    width: 20%;
    text-align: center;
    cursor: pointer;
}

/*  */

.message-body-container {
    display: flex;
}

.message-body {
    padding: 30px 10px;
    font-size: 1.2rem;
    
}

@media screen and (max-width: 1000px) {
    .message-body {
        font-size: 1.4rem;
    }
}

.message-push {
    padding: 30px 20px;
    background-color: #ffe895
}

.message-body.push {
    flex-grow: 2;
}

.i-icon {
    height: 100%;
    
}

/* */

.message-toolbar {
    text-align: center;
    background-color: #d6d6d6;
}

.message-toolbar > div {
    display: inline-block;
    width: 20%;
    padding: 8px 0;
    cursor: pointer;
}

.message-toolbar > button {
    display: inline-block;
    width: 20%;
    padding: 8px 0;
    cursor: pointer;
    font-size: 1rem;
    border: 0;
    
}

@media screen and (max-width: 1000px) {
    .message-toolbar > div {
        padding: 15px 0;
    }
}

.message-toolbar-flag1 {
    background-color: #00ff4e;
}

.message-toolbar-flag2 {
    background-color: #5a9cff;
}

.message-toolbar-flag3 {
    background-color: #ff9a44;
}

.message-toolbar-bin {
    width: 40% !important;
    cursor: pointer;
    background-color: #d6d6d6;
}






/* tag items */
/* will change all this to variables at some stage */

.tagged1 {
    background-color: #00ff4e;
}

.tagged2 {
    background-color: #5a9cff;
}

.tagged3 {
    background-color: #ff9a44;
}





/* default font (use for buttons that should be like divs etc */

.default-font {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    color: black;
}