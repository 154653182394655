.overview-container {
    padding: 15px;
    /* background-color: #b8b8b8; */
    height: 90%;
    max-width: 400px;
}

@media screen and (max-width: 1000px) {
    .overview-container {
        max-width: none;
    }
}


.body-container {
    margin-top: 70px;
}