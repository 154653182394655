.flex-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    border-bottom: solid 1px black;
    display: flex;
    padding: 10px 0;
    background-color: #eaeaea;
}

.flex-item {
    /* padding: 5px 15px;
    padding-top: inherit;
    padding-left: 15px;
    padding-right: 15px; */
    padding: 10px 15px;
}

@media screen and (max-width: 450px) {
    .hide-small {
        display: none;
    }
    
}

a.nav-link, a.nav-link:visited {
    color: blue;
}